import React, { lazy} from 'react';
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "leaflet/dist/leaflet.css";
import { RecoilRoot } from "recoil";
const Router = lazy(() => import("./router/Router"));

const App = () => {
  return (
    <div>
      <RecoilRoot>
        <Router />
      </RecoilRoot>
    </div>
  );
}

export default App;
